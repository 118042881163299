import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../../components/Layout/SEO';
import Layout from '../../components/Layout/Layout';
import CaseStudyHeader from '../../components/CaseStudy/CaseStudyHeader';
import SectionLight from '../../components/shared/SectionLight';
import CaseStudySectionHeader from '../../components/CaseStudy/CaseStudySectionHeader';
import CaseStudySection from '../../components/CaseStudy/CaseStudySection';
import CaseStudyText from '../../components/CaseStudy/CaseStudyText';
import CaseStudyImage from '../../components/CaseStudy/CaseStudyImage';
import HeadingArrow from '../../components/shared/HeadingArrow';
import Contact from '../../components/shared/Contact';

import data from '../../data/rexford';

const RexfordPage = ({ location }) => {
	const { name, description, techUsed, sourceLinks } = data;
	const { alt } = data.images.main;

	const { demoLink } = data;

	const links = (
		<div>
			<a href={demoLink.link}>{demoLink.text}</a>
		</div>
	);
	const imageData = useStaticQuery(imageQuery);
	return (
		<Layout location={location}>
			<SEO title='Rexford Knives' />
			<CaseStudyHeader
				title={name}
				text={description}
				image={imageData.mainImg}
				alt={alt}
				links={links}
				sourceLinks={sourceLinks}
				techUsed={techUsed}
			/>
			<SectionLight>
				<CaseStudySectionHeader>
					A New Look for a Manufacturing Powerhouse
				</CaseStudySectionHeader>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>Project Overview</HeadingArrow>
						<p>
							This project was a collaboration between fellow
							developer Tyson Comer and I to revamp the digital
							presence for Rexford Knives. The company already had
							an established brand, but their old website was
							looking a bit dated and had some challenges with
							mobile responsiveness.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.oldWebsite.childImageSharp.fluid}
						alt='rexford knives old website'
					/>
				</CaseStudySection>
				<CaseStudySection reverse>
					<CaseStudyText>
						<HeadingArrow>Refreshing the View</HeadingArrow>
						<p>
							With the goal of mobile optimization in mind, we
							built several gallery pages that showcase the
							owner's product photography. Each page resizes
							responsively based on the user's screen size without
							needing to excessively rewrite the code base.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.productPage.childImageSharp.fluid}
						alt='rexford knives product page'
					/>
				</CaseStudySection>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>Keeping Track of History</HeadingArrow>
						<p>
							The company owner also needed a way to display
							manufacturing information to serve as a source of
							documentation of product quality and authenticity.
							We solved this challenge by creating a manufacturing
							history table that can be updated each time a new
							product launches.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.figmaDesign.childImageSharp.fluid}
						alt='rexford knives figma design'
					/>
				</CaseStudySection>
				<CaseStudySection reverse>
					<CaseStudyText>
						<HeadingArrow>Crafting a Responsive Table</HeadingArrow>
						<p>
							Building data tables that display well on mobile
							phones can be a challenge, due to limited screen
							space, and a horizontal data layout that's most
							commonly used. I solved this challenge by
							implementing a mobile table pattern that lets the
							user scroll horizontally without breaking the rest
							of the design.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.mobileTable.childImageSharp.fluid}
						alt='rexford knives mobile history table'
					/>
				</CaseStudySection>
				<Contact
					title='Interested in Working Together?'
					linkText='Let Me Know'
				/>
			</SectionLight>
		</Layout>
	);
};

export default RexfordPage;

const imageQuery = graphql`
	query RexfordQuery {
		mainImg: file(
			relativePath: { eq: "case-study/rexford/rexford-multi.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		oldWebsite: file(
			relativePath: { eq: "case-study/rexford/rexford-old-website.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		productPage: file(
			relativePath: {
				eq: "case-study/rexford/rexford-product-image-page.png"
			}
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		figmaDesign: file(
			relativePath: { eq: "case-study/rexford/rexford-figma-design.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		mobileTable: file(
			relativePath: { eq: "case-study/rexford/rexford-mobile-table.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`;
